import SVG from "svg.js";
import { $, _ } from "legacy-js/vendor";
import React from "reactn";
import {
    ELEMENT_TRANSITION, ELEMENT_TRANSITION_DURATION,
    getHTMLProps,
    getSVGStyleProps,
    getSVGTextProps,
    SVGGroup
} from "legacy-js/core/utilities/svgHelpers";
import { Component } from "react";
import { v4 as uuid } from "uuid";

import { BaseElement } from "./BaseElement";

class SVGElement extends BaseElement {
    constructor(options) {
        super(options);

        this.animateRef = React.createRef();
    }

    get allowDecorationStyles() {
        return false;
    }

    async load() {
        // do nothing
    }

    _calcProps(props, options) {
        let { size } = props;
        props.layer = -1;
        return { size };
    }

    getBackgroundColor(forElement) {
        if (forElement !== this) {
            // return this.styles.fillColor;
            return this.styles.resolved_fillColor;
        } else {
            return this.parentElement.getParentBackgroundColor(forElement);
        }
    }

    renderElement(transition) {
        let props = this.calculatedProps;

        const styles = this.shouldApplyTextStyle ? getSVGTextProps(this.styles) : getSVGStyleProps(this.styles);

        if (this.isNew) {
            styles.animationName = "element-fade-in";
            styles.animationDuration = ELEMENT_TRANSITION;
            transition = false;
        }

        if (this.isDeleted) {
            styles.opacity = 0;
        } else if (this.isAnimating && "fadeInProgress" in this.animationState) {
            styles.opacity = (styles.opacity ?? 1) * this.animationState.fadeInProgress;
        } else if (this.isDimmed) {
            styles.opacity = (styles.opacity ?? 1) * 0.6;
        }

        if (transition) {
            // styles.transition = getElementTransition(ELEMENT_TRANSITION);
        }

        return this.renderSVG(props, styles, transition);
    }

    renderSVG(props, styles, transition) {

    }
}

export class DivDecorationElement extends BaseElement {
    _renderElement(transition) {
        let htmlProps = getHTMLProps(this.calculatedProps, this.styles, transition);

        return (<div key={this.id} {...htmlProps} />);
    }
}

class SVGGroupElement extends SVGElement {
    _create() {
        this.svg = this.parentSVG.group();
    }

    _render(transition) {
        let curTransform = $(this.svg.node).transform();
        if (this.position && (this.position.x != curTransform.x || this.position.y != curTransform.y)) {
            this.svg.setPosition(this.position, transition);
        }
    }

    clear() {
        this.svg.clear();
    }
}

class SVGCustomElement extends SVGElement {
    _create() {
        this.svg = this.parentSVG.group();
    }

    _render(transition) {
        let curTransform = $(this.svg.node).transform();
        if (this.position && (this.position.x != curTransform.x || this.position.y != curTransform.y)) {
            this.svg.setPosition(this.position, transition);
        }
    }

    clear() {
        this.svg.clear();
    }
}

class SVGConnectorGroup extends SVGElement {
    _create() {
        this.svg = this.parentSVG.group();
    }

    _render(transition) {
        let curTransform = $(this.svg.node).transform();
        if (this.position && (this.position.x != curTransform.x || this.position.y != curTransform.y)) {
            this.svg.setPosition(this.position, transition);
        }
    }

    clear() {
        this.svg.clear();
    }
}

class SVGPolylineElement extends SVGElement {
    renderSVG(props, styles) {
        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <polyline
                    id={this.id}
                    ref={this.ref}
                    points={props.path.map(v => v.join(",")).join(" ")}
                    style={styles}
                />
            </SVGGroup>
        );
    }
}

class SVGPolygonElement extends SVGElement {
    renderSVG(props, styles, transition) {
        const path = props.path || this.model.path;

        let pathToRender;
        if (transition && this.lastPath && !_.isEqual(this.lastPath, path)) {
            this.isAnimating = true;
            pathToRender = this.lastPath;

            const svg = SVG(this.ref.current.ref.current);
            const svgPath = svg.children()[0];
            const animation = svgPath.animate(0, "-", 0).plot(path).pause();

            const duration = ELEMENT_TRANSITION_DURATION;
            const easing = $.Velocity.Easings["ease-in-out"];

            let startTime;
            const doAnimation = timestamp => {
                if (!startTime) {
                    startTime = timestamp;
                }
                const percentComplete = easing(Math.min((timestamp - startTime) / duration, 1));
                animation.at(percentComplete);

                if (percentComplete < 1) {
                    window.requestAnimationFrame(doAnimation);
                } else {
                    this.isAnimating = false;
                    this.lastPath = path;
                }
            };

            window.requestAnimationFrame(doAnimation);
        } else {
            pathToRender = this.lastPath = path;
        }

        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <polygon
                    id={this.id}
                    points={pathToRender}
                    style={styles}
                >
                </polygon>
            </SVGGroup>
        );
    }
}

class SVGPathElement extends SVGElement {
    renderSVG(props, styles, transition) {
        const path = props.path;

        let pathToRender;
        if (transition && this.lastPath && !_.isEqual(this.lastPath, path)) {
            this.isAnimating = true;
            pathToRender = this.lastPath;

            const svg = SVG(this.ref.current.ref.current);
            const svgPath = svg.children()[0];
            const animation = svgPath.animate(0, "-", 0).plot(path).pause();

            const duration = ELEMENT_TRANSITION_DURATION;
            const easing = $.Velocity.Easings["ease-in-out"];

            let startTime;
            const doAnimation = timestamp => {
                if (!startTime) {
                    startTime = timestamp;
                }
                const percentComplete = easing(Math.min((timestamp - startTime) / duration, 1));
                animation.at(percentComplete);

                if (percentComplete < 1) {
                    window.requestAnimationFrame(doAnimation);
                } else {
                    this.isAnimating = false;
                    this.lastPath = path;
                    // We have to refreshRender in order to get rid of the animation
                    // state which sometimes mess with future renders
                    this.refreshRender();
                }
            };

            window.requestAnimationFrame(doAnimation);
        } else {
            pathToRender = this.lastPath = path;
        }

        return (
            <SVGGroup ref={this.ref} key={this.id} className={this.id}>
                <path
                    id={this.id}
                    d={pathToRender}
                    style={styles}
                >
                </path>
            </SVGGroup>
        );
    }
}

class SVGRectElement extends SVGElement {
    renderSVG(props, styles) {
        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <rect
                    id={this.id}
                    x={props.bounds.left}
                    y={props.bounds.top}
                    width={props.bounds.width}
                    height={props.bounds.height}
                    rx={props.cornerRadius}
                    style={styles}
                />
            </SVGGroup>
        );
    }
}

class SVGCircleElement extends SVGElement {
    renderSVG(props, styles) {
        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <circle
                    id={this.id}
                    r={Math.min(props.bounds.width, props.bounds.height) / 2}
                    cx={props.bounds.centerH}
                    cy={props.bounds.centerV}
                    style={styles}
                />
            </SVGGroup>
        );
    }
}

class SVGEllipseElement extends SVGElement {
    renderSVG(props, styles) {
        return (
            <SVGGroup ref={this.ref} key={this.id}>
                <ellipse
                    id={this.id}
                    rx={props.bounds.width / 2}
                    ry={props.bounds.height / 2}
                    cx={props.bounds.centerH}
                    cy={props.bounds.centerV}
                    style={styles}
                />
            </SVGGroup>
        );
    }
}

export class TransitionableSVGPath extends Component {
    constructor(props) {
        super(props);

        this.pathRef = React.createRef();
        this.pathId = `path${uuid()}`;

        this.state = {
            path: props.d
        };
    }

    componentDidUpdate(prevProps) {
        const { d, transition } = this.props;

        if (prevProps.d !== d) {
            const newPath = d;

            if (transition) {
                const svg = SVG($(this.pathRef.current).closest("svg")[0]);
                const svgPath = svg.select("#" + this.pathId).members[0];
                const animation = svgPath.animate(0, "-", 0).plot(newPath).pause();
                const easing = $.Velocity.Easings["ease-in-out"];

                let startTime;
                const doAnimation = timestamp => {
                    if (!startTime) {
                        startTime = timestamp;
                        window.requestAnimationFrame(doAnimation);
                        return;
                    }

                    const percentComplete = easing(Math.min((timestamp - startTime) / ELEMENT_TRANSITION_DURATION, 1));
                    if (percentComplete < 1) {
                        animation.at(percentComplete);
                        window.requestAnimationFrame(doAnimation);
                    } else {
                        this.setState({ path: newPath });
                    }
                };

                window.requestAnimationFrame(doAnimation);
            } else {
                this.setState({ path: newPath });
            }
        }
    }

    render() {
        const { path } = this.state;
        const { fill, stroke, strokeWidth, strokeDasharray, strokeDashoffset, opacity } = this.props;

        return (<path
            id={this.pathId}
            ref={this.pathRef}
            d={path}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeDashoffset={strokeDashoffset}
            strokeDasharray={strokeDasharray}
            opacity={opacity}
        />);
    }
}

export {
    SVGElement,
    SVGGroupElement,
    SVGCustomElement,
    SVGConnectorGroup,
    SVGPolylineElement,
    SVGPolygonElement,
    SVGPathElement,
    SVGRectElement,
    SVGCircleElement,
    SVGEllipseElement
};
